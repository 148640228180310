import { useRef } from 'react';
import MoviesScenesGrid from '../../../../components/MoviesScenesGrid/MoviesScenesGrid';
import { isWeb } from '../../../../services/util-service/util.service';
import { getStudioDetailsRoute } from '../../../../services/navigation/navigation.service.routes';
import useMoviesScenesData from '../../../../hook/useMoviesScenesData';
import { navigationOptions } from '../../../../constants/navigation';
import useInViewport from '../../../../hook/useInViewport';

const StudioDetailsPageMovieSiteSpecific = (props) => {
  const { studioId, studioName, title, location = {} } = props;

  const containerRef = useRef(null);
  const isVisible = useInViewport(containerRef);

  const { videos, sort, type, loading, error, onTypeChange, onSortByClick } = useMoviesScenesData({
    location,
    isVisible,
    videosType: navigationOptions.movies,
    hookParams: { studios_id: studioId },
  });

  const slicedVideos = isWeb() ? videos.slice(0, 6) : videos.slice(0, 4);

  return (
    <div className="StudioDetailsPageMovieSiteSpecific">
      <MoviesScenesGrid
        data={slicedVideos}
        loading={loading}
        type={type}
        sort={sort}
        onShowMeClick={onTypeChange}
        onSortByClick={onSortByClick}
        error={error}
        title={title}
        viewAllLink={{ url: getStudioDetailsRoute(studioId, studioName), text: 'view all' }}
        hideNavigation
        refProp={containerRef}
      />
    </div>
  );
};

StudioDetailsPageMovieSiteSpecific.displayName = 'StudioDetailsPageMovieSiteSpecific';

export default StudioDetailsPageMovieSiteSpecific;
