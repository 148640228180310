import React, { Component, lazy } from 'react';
import { globalHistory, Location, Redirect, Router, navigate } from '@reach/router';
import { withTranslation } from 'react-i18next';

import MainDashboardHeader from './MainDashboardHeader/MainDashboardHeader';
import MainDashboardView from './MainDashboardView/MainDashboardView';
import SideMenu from '../../components/mobile/SideMenu/SideMenu';
import JustAddedComponent from './MainDashboardView/JustAddedComponent/JustAddedComponent';
import {
  getFavoritesPageRoute,
  getJoinLinkRoute,
  getSignInPageRoute,
  getMostWatchedPageRoute,
  getOriginalsScenesPageRoute,
  routes,
} from '../../services/navigation/navigation.service.routes';
import { getWhiteLabelConfig } from '../../services/properties/properties.service';
import { logError } from '../../services/log/log.service';
import { getPlayerConfig } from '../../services/player/player.service';
import { isWeb, setContentScrolltop } from '../../services/util-service/util.service';

import { DashboardController } from '../../controllers/dashboard-controller/DashboardController';
import { MainDashboardController } from './MainDashboardController';
import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';

import './MainDashboard.scss';
import '../../styles/livechat_buttons.scss';
import throttle from 'lodash/throttle';
import { getUserNATSCode, setUserNATSCode } from '../../services/nats-service/nats.service';
import { getURLPath } from '../../services/navigation/navigation.service';
import { isUserLoggedIn, singleSignOff } from '../../services/token-service/token.service';
import MobileSecondaryNavigation from '../../components/mobile/MobileSecondaryNavigation/MobileSecondaryNavigation';
import { env } from '../../env';
import { UsersController } from '../../controllers/users-controller/users.controller';
import { createJoinLinkFromLastCode } from '../../links';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import DirectorPage from './DirectorPage/DirectorPage';
import BannerSetViewFunc from '../../components/BannerSetView/BannerSetViewFunc.jsx';
import { LoadingText } from '../../components/LoadingMask/LoadingMask.js';
import AvsPage from '../AvsPage/AvsPage.js';
import StreamTest from '../StreamTest/StreamTest.js';
import AgeWarningOverlay from '../../components/AgeWarningOverlay/AgeWarningOverlay.js';
import Footer from '../../components/Footer/Footer.js';

const SearchResultsPage = lazy(() => import('./SearchResultsPage/SearchResultsPage'));
const MyAccountPage = lazy(() => import('./MyAccountPage/MyAccountPage'));
const MemberDeals = lazy(() => import('./MemberDealsPage/MemberDealsPage'));
//const MyDealsPage = lazy(() => import("./MyAccountPage/MyDeals/MyDeals"));
const CompliancePage = lazy(() => import('./CompliancePage/CompliancePage'));
const TopTen = lazy(() => import('./TopTen/TopTen'));
const OriginalsPage = lazy(() => import('./OriginalsPage/OriginalsPage'));
const ThemeDetailsPage = lazy(() => import('./ThemesPage/ThemeDetailsPage/ThemeDetailsPage'));
const MovieDetailsPage = lazy(() => import('./MovieDetailsPage/MovieDetailsPage'));
const StudioDetailsPage = lazy(() => import('./StudiosPage/StudioDetailsPage/StudioDetailsPage'));
const SeriesPage = lazy(() => import('./SeriesPage/SeriesPage'));
const JustAddedPage = lazy(() => import('./JustAddedPage/JustAddedPage'));
const StarsPage = lazy(() => import('./StarsPage/StarsPage'));
const StudiosPage = lazy(() => import('./StudiosPage/StudiosPage'));
const ThemesPage = lazy(() => import('./ThemesPage/ThemesPage'));
const PlaylistsPage = lazy(() => import('./PlaylistsPage/PlaylistsPage'));
const NewsLetterPage = lazy(() => import('./NewsLetterPage/NewsLetterPage'));
const NewsletterConfirmPage = lazy(() => import('../../components/NewsletterSignUp/NewsletterConfirm.js'));
const HelpPage = lazy(() => import('./HelpPage/HelpPage'));
const TermsOfUsePage = lazy(() => import('./TermsOfUsePage/TermsOfUsePage'));
const PrivacyPolicyPage = lazy(() => import('./PrivacyPolicyPage/PrivacyPolicyPage'));
const StreamBlocked = lazy(() => import('./StreamBlockedPage/StreamBlocked'));
const ForgotPasswordPage = lazy(() => import('./ForgotPasswordPage/index.js'));
const Streamate = lazy(() => import('./Streamate/Streamate.js'));
const Live = lazy(() => import('./Live/Live.js'));
const WeekendsPage = lazy(() => import('./WeekendsPage/WeekendsPage'));
const Mask4MaskPage = lazy(() => import('./WeekendsPage/Mask4MaskPage.js'));
const MissPineapple2021Page = lazy(() => import('./WeekendsPage/MissPineapple2021Page.js'));
const MrMan2020Page = lazy(() => import('./WeekendsPage/MrMan2020Page.js'));
const Party20thPage = lazy(() => import('./WeekendsPage/Party20thPage.js'));
const Pride2020Page = lazy(() => import('./WeekendsPage/Pride2020Page.js'));
const SpecialOffers = lazy(() => import('../SpecialOffers/SpecialOffers'));
const Free = lazy(() => import('./SpecialPromos/Free/Free'));
const FreeRideOrDieBonusVideo = lazy(() => import('./SpecialPromos/Free/FreeRideOrDieBonusVideo'));
const FreeRideOrDieBonusBehindScenes = lazy(() => import('./SpecialPromos/Free/FreeRideOrDieBonusBehindScenes'));
const FreeRideOrDieBonusHadItComing = lazy(() => import('./SpecialPromos/Free/FreeRideOrDieBonusHadItComing'));
const FreeRideOrDieBonusMeetTheWarden = lazy(() => import('./SpecialPromos/Free/FreeRideOrDieBonusMeetTheWarden'));
const FavoritesPage = lazy(() => import('./FavoritesPage/FavoritesPage'));
const UserPlaylistsPage = lazy(() => import('./UserPlaylistsPage/UserPlaylistsPage'));
const SignInPage = lazy(() => import('./SignInPage/SignInPage'));
const ViewingHistoryPage = lazy(() => import('./ViewingHistoryPage/ViewingHistoryPage'));
const NotFound = lazy(() => import('../NotFound/NotFound'));
const ModalContainer = lazy(() => import('../../components/ModalContainer/ModalContainer'));
const Notifications = lazy(() => import('../../components/Notifications/Notifications'));
const MobileUserActions = lazy(() => import('../../components/mobile/MobileUserActions/MobileUserActions'));

export const frontPosterSizes = {
  // updated on resize
  grid: window.innerWidth / 3,
  details: window.innerWidth,
};

class MainDashboard extends Component {
  locationPathnameRef = React.createRef();

  routeChangeListenerUnsubscribe;

  state = {
    loading: true,
    sideMenuVisible: false,
    whitelabelNatsCode: undefined,
    userStateChange: undefined,
    searchDropdownValue: '',
  };

  subscriptions = {};

  timeoutId;

  constructor(props) {
    super(props);
    window.addEventListener('resize', this.onResize);
    this.throttledTouchStart = throttle(this.onTouchStart, 1000);
    this.subscriptions.userStateChange = UsersController.userStateChange.subscribe(this.onUserStateChange);
  }

  onUserStateChange = () => {
    this.setState({
      userStateChange: new Date().getTime(),
    });
  };

  isSignuoFormOpen = () => {
    if (SignInController.isSignInDisplayed()) {
      SignInController.closeSignInDialog();
    }
  };

  onScrollCloseSignupForm = (type) => {
    if (!isWeb()) return; // iPhone Safari/Chrome bug fix
    const event = type === 'add' ? 'addEventListener' : 'removeEventListener';
    document[event]('scroll', this.isSignuoFormOpen);
  };

  componentDidMount() {
    this.routeChangeListenerUnsubscribe = globalHistory.listen(this.routeChangeListener);
    const promises = [
      getWhiteLabelConfig().catch(this.onRequestFailure),
      getPlayerConfig().catch(this.onRequestFailure),
    ];
    Promise.all(promises).then(this.setDataLoaded).catch(this.onRequestFailure);
    // window.dataLayer.push({ event: 'pageview' });
    singleSignOff(this.onUserStateChange, 'add');
    this.onScrollCloseSignupForm('add');
  }

  componentWillUnmount() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
    this.throttledTouchStart?.cancel();
    window.removeEventListener('resize', this.onResize);
    this.routeChangeListenerUnsubscribe();
    singleSignOff(this.onUserStateChange, 'remove');
    this.onScrollCloseSignupForm('remove');
  }

  handleSearchDropdownValue = (value) => {
    this.setState({
      searchDropdownValue: value,
    });
  };

  configureNATSToken = () => {
    const storedNatsCode = getUserNATSCode(); // from localStorage
    const { whitelabelNatsCode } = this.state; // from Gandolf
    const querySearch = new URLSearchParams(window.location.search); // from url
    const queryNatsCode = querySearch.get('nats');
    let nats_code = null;

    if (queryNatsCode) {
      setUserNATSCode(queryNatsCode);
      this.getNatsCode(queryNatsCode);
    } else if (whitelabelNatsCode) {
      nats_code = whitelabelNatsCode;
    }

    if (!storedNatsCode) {
      if (nats_code) {
        setUserNATSCode(nats_code);
      }
      this.getNatsCode(nats_code);
    }
  };

  getNatsCode = (nats_code = null) => {
    // request to NATS so NATS can set its own cookies
    if (!nats_code) return;

    // eslint-disable-next-line
    const url = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/.exec(env.REACT_APP_JOIN_NOW_URL)[0];
    const trackLink = `${url}/track/${nats_code}`;

    fetch(trackLink, {
      method: 'GET',
      mode: 'no-cors',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => console.log('getNatsCode - ' + err));
  };

  handleMainContainerClick = (event) => {
    const { sideMenuVisible } = this.state;
    if (sideMenuVisible) {
      this.setState({ sideMenuVisible: false });
    }
    MainDashboardController.notifyMainDashboardClick(event);
  };

  onRequestFailure = (error) => {
    logError(error);
  };

  onResize = () => {
    const width = Math.round(window.innerWidth);
    frontPosterSizes.grid = Math.round(width / 3);
    frontPosterSizes.details = width;
  };

  onTouchStart = (event) => {
    MainDashboardController.notifyUserTouchStart();
  };

  renderSideMenu = () => {
    if (isWeb()) return null;
    const { sideMenuVisible } = this.state;
    const className = sideMenuVisible ? 'Visible' : '';
    return <SideMenu className={className} onItemClick={this.toggleMobileMenu} />
  };

  routeChangeListener = ({ action, location }) => {
    const { pathname } = location;

    if (!pathname.includes('http')) {
      this.setState({
        userStateChange: action,
      });
    }

    if (isWeb()) {
      if (this.locationPathnameRef.current !== location.pathname) {
        setContentScrolltop();
      }
      this.locationPathnameRef.current = location.pathname;
    } else {
      // can be updated with MutationObserver
      if (this.locationPathnameRef.current !== location.href) {
        setContentScrolltop();
      }
      this.locationPathnameRef.current = location.href;
    }
  };

  setDataLoaded = (responses = []) => {
    const { data = {} } = responses[0]?.data || {};
    const { propertyPlayerConfig = {} } = responses[1]?.data?.data || {};

    DashboardController.setPlayerConfig(propertyPlayerConfig);
    DashboardController.setWhiteLabelConfig(data);

    this.setState(
      {
        whitelabelNatsCode: data.nats_code,
        loading: false,
      },
      this.configureNATSToken.bind(this),
    );
  };

  toggleMobileMenu = (closeSignInDialog = true) => {
    if (SignInController.isSignInDisplayed() && closeSignInDialog) {
      SignInController.closeSignInDialog();
    }

    this.setState((prevState) => {
      return {
        sideMenuVisible: !prevState.sideMenuVisible,
      };
    });
  };

  renderJustAdded = ({ pathname }) => {
    const isHomePage = pathname === routes.root;
    const isVisible =
      isHomePage || ((pathname.includes(routes.justAdded) || pathname.includes(routes.mostWatched)) && isWeb());
    return isVisible ? <JustAddedComponent showFilters={isUserLoggedIn() && isHomePage && isWeb()} /> : null;
  };

  renderMemberUpsellBottomBanner = () => {
    const urlPath = getURLPath();
    return (urlPath.includes(routes.myAccount) || urlPath.includes(routes.help)) && isWeb() && isUserLoggedIn() ? (
      <BannerSetViewFunc bannerName="memberUpsellBottom" />
    ) : null;
  };

  render() {
    const { t } = this.props;
    const { searchDropdownValue, loading } = this.state;

    if (window.location.pathname.includes('/join')) {
      const querySearch = new URLSearchParams(window.location.search);
      const queryNatsCode = querySearch.get('nats');

      if (queryNatsCode) {
        const joinURL = createJoinLinkFromLastCode(queryNatsCode); // create new link
        setUserNATSCode(queryNatsCode); // update storage with new code
        this.getNatsCode(queryNatsCode); // update cookies via nats
        navigate(joinURL);
      } else {
        navigate(getJoinLinkRoute());
      }

      return null;
    }

    const path = window.location.pathname;
    if (path.includes(`/${routes.ageVerificationLanding}`)) {
      return <AvsPage />;
    }

    if (path === `/${routes.diag}`) {
      return <StreamTest />;
    }

    return (
      <ErrorBoundary>
        {!loading ? (
          <div className="MainDashboard" onClick={this.handleMainContainerClick}>
            <AgeWarningOverlay />
            <MainDashboardHeader
              path={`${routes.root}/*`}
              toggleMobileMenu={this.toggleMobileMenu}
              handleSearchDropdownValue={this.handleSearchDropdownValue}
            />
            <div
              className="MainContent"
              onTouchStart={(event) => {
                event.persist();
                this.throttledTouchStart(event);
              }}
              onScroll={this.isSignuoFormOpen}
            >
              <div className="MainContent-inner">
                {this.renderSideMenu()}
                <div className="MainContainer">
                  <Location>
                    {({ location }) => (
                      <>
                        {/* {this.handleJoinLink(location)} */}
                        {this.renderJustAdded(location)}
                        <Router primary={false} location={location}>
                          <MainDashboardView path={routes.root} />
                          <CompliancePage path={routes.compliance} />
                          <DirectorPage path={routes.directorInfo} />
                          <FavoritesPage path={getFavoritesPageRoute()} />
                          <HelpPage path={routes.help} />
                          <JustAddedPage path={routes.justAdded} />
                          <JustAddedPage path={routes.mostWatched} />
                          <UserPlaylistsPage path={routes.userPlaylists} />
                          <SignInPage path={getSignInPageRoute()} />
                          <MemberDeals path={routes.memberDeals} />
                          <MovieDetailsPage path={routes.movieScenes} />
                          <MovieDetailsPage path={routes.movieInfo} />
                          <MyAccountPage path={routes.myAccount} />
                          {/*<MyDealsPage path={routes.myDeals} />*/}
                          <NewsLetterPage path={routes.newsletter} />
                          <NewsletterConfirmPage path={routes.newsletterconfirm} />
                          <OriginalsPage path={routes.originals} />
                          <PlaylistsPage path={routes.playlistsAnything} />
                          <PrivacyPolicyPage path={routes.privacyPolicy} />
                          <StarsPage path={routes.starsAnything} />
                          <StudiosPage path={routes.studios} />
                          <StudioDetailsPage path={routes.studiosInfo} />
                          <TermsOfUsePage path={routes.termsOfUse} />
                          <TopTen path={routes.top10} />
                          <SearchResultsPage path={routes.search} searchDropdownValue={searchDropdownValue} />
                          <SeriesPage path={routes.seriesInfo} />
                          <ThemesPage path={routes.themes} />
                          <ThemeDetailsPage path={routes.themeInfo} />
                          <ThemeDetailsPage path={routes.sexActInfo} />
                          <ViewingHistoryPage path={routes.viewingHistory} />
                          <StreamBlocked path={routes.streamblocked} />
                          <ForgotPasswordPage path={routes.forgotpasswordpage} />
                          <WeekendsPage path={routes.weekends} />
                          <Mask4MaskPage path={routes.mask4mask} />
                          <MissPineapple2021Page path={routes.misspineapple2021} />
                          <MrMan2020Page path={routes.mrman2020} />
                          <Party20thPage path={routes.party20th} />
                          <Pride2020Page path={routes.pride2020} />
                          <SpecialOffers path={routes.specialOffers} />
                          <Free path={routes.free} />
                          <FreeRideOrDieBonusVideo path={routes.freerideordiebonusvideo} />
                          <FreeRideOrDieBonusBehindScenes path={routes.freerideordiebonusbehindscenes} />
                          <FreeRideOrDieBonusHadItComing path={routes.freerideordiebonushaditcoming} />
                          <FreeRideOrDieBonusMeetTheWarden path={routes.freerideordiebonusmeetthewarden} />
                          <Streamate path={routes.streamate} />
                          <Live path={routes.live} />
                          <Redirect to={getMostWatchedPageRoute()} from={`/mostwatched`} noThrow />
                          <Redirect to={getOriginalsScenesPageRoute(1, 'Newest')} from={routes.originals} noThrow />
                          <NotFound
                            default
                            linkWrapperClass="Exclamation"
                            iconClass="fas fa-exclamation"
                            title={t('MainDashboard.notFoundTitle')}
                            subtitleRowOne={t('MainDashboard.notFoundRowOne')}
                          />
                        </Router>
                        <Footer />
                        {this.renderMemberUpsellBottomBanner()}
                      </>
                    )}
                  </Location>
                </div>
                {this.renderSecondaryMobileNavigation()}
                {this.renderMobileUserActions()}
              </div>
            </div>
            <ModalContainer />
            <Notifications />
          </div>
        ) : (
          <LoadingText />
        )}
      </ErrorBoundary>
    );
  }

  // show filters on mobile on particular page
  renderSecondaryMobileNavigation = () => {
    const pathname = window.location.pathname;
    const path = `/${pathname.split('/')[1]}`;

    const allowedPages = [
      routes.root,
      routes.theme,
      routes.justAdded,
      routes.mostWatched,
      routes.originals,
      routes.trentonducatioriginals,
      routes.stars,
      routes.studios,
      routes.search,
      routes.series,
      routes.director,
    ];

    const allowedPagesWithSlash = allowedPages.map((route) => {
      if (route === '/') return null;
      return route.startsWith('/') ? route : `/${route}`;
    });

    if (allowedPagesWithSlash.includes(path)) {
      return <MobileSecondaryNavigation />;
    }

    return null;
  };

  renderMobileUserActions = () => {
    if (isWeb()) return null;
    return <MobileUserActions />;
  };
}

MainDashboard.displayName = 'MainDashboard';

export default withTranslation()(MainDashboard);